body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.containerUPSOM {
    padding: 10px 20px 0 20px;
}



.navbar {
    padding: 0.5rem 1rem 0 1rem !important;
}

.header_wrapper_inline {
    background: url(https://www.communications.pitt.edu/sites/all/themes/pitt_theme/img/bg_header.png) no-repeat center center #003594;
    /* background: repeat-x left center #003594;*/
    background-size: cover;
}


.header_wrapper_inline .pitt_shield img {
    max-width: 148px;
}



@media(min-width: 992px) {

    .site_logo {
        border-left: 1px solid rgba(255,255,255,0.3);
        padding-left: 20px;
        margin-left: 20px;
        margin-top: 0;
    }

    .navbar-toggler {
        display: none;
    }
}

#headerContainer {
    padding-top: 30px;
}

img {
    vertical-align: middle;
    border: 0;
}



.site_logo {
    font-family: "cooper_hewittmedium","Open Sans","Nelvetica Neue",arial,sans-serif;
    font-size: 25px;
    font-weight: 400;
    padding-left: 20px;
    margin-left: 20px;
    color: #fff;
    line-height: 30px;
}

.navbar-toggler .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    line-height: 30px;
    background-color: #ffb81c;
}

.icon-bar {
    margin-top: 5px;
}

.smalllogo {
    font-size: 1.0rem;
    display: block;
}



.site-info__navbar-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
}

site-info__navbar-toggle .navbar-toggle {
    position: relative;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 50px;
    padding: 0;
    margin: -1px;
    /*            overflow: hidden;
                */ clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}


.nav-item {
    margin-right: 5px;
}

    .nav-item:hover {
        background-color: #3366ff;
    }

.active {
    background-color: #ffb81c;
}

.nav-item a {
    color: white;
}

#primary-links {
    margin-top: 20px;
}
