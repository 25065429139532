* {
    box-sizing: border-box;
    font-family:;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5;
    color: #24292e;
    background-color: #fff;
}

/* .app {
    margin: 2rem;
} */

a {
    color: #08c;
}

code {
    background: #eee;
    padding: .1rem;
    font-family: 'Menlo';
    font-size: 13px;
    color: #ff00aa;
}

input, select, DatePicker, textarea {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    /*display: block;*/
    border-radius: 4px;
    border: 1px solid #ccc;
    overflow: visible !important;
}

select {
    padding: .5rem;
    font-size: 16px;
    /*display: block;*/
    border-radius: 4px;
    border: 1px solid #ccc;
    overflow: visible !important;
}

#footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 16px;
    width: 100%;
    display: block;
}

input:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
}

.showmaxrecords {
    max-width: 150px;
}

input.error {
    border-color: red;
}

label {
    font-size: 16px;
    color: #444;
    display: block;
    margin-bottom: .5rem;
    padding-top: 10px;
}

.input-feedback {
    color: red;
    margin-top: .25rem;
}

.inline {
    display: inline;
    padding-top: 5px;
    vertical-align: middle;
}

button.main {
    max-width: 150px;
    margin: 20px 0;
    padding: 12px 20px;
    border-style: none;
    border-radius: 5px;
    background-color: #003594;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
}

button.approval {
    max-width: 10px;
    margin: 8px 0;
    padding: 4x 7px;
    border-style: none;
    border-radius: 2px;
    background-color: #003594;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
}



    button:disabled {
        opacity: .5;
        cursor: not-allowed !important;
    }

    button + button {
        margin-left: .5rem;
    }

    button.outline {
        background-color: #eee;
        border: 1px solid #aaa;
        color: #555;
    }

div.otherResearchTypesDiv {
    margin-left: 20px;
}

input.agreeText, label.agreeText, .agreeControl, input.iAmNotARobot {
    display: inline;
    max-width: 25px;
    font-weight: normal;
    border: none;
    border-radius: 0;
    padding: 0;
}

#mentorSection, #projectSection {
    max-width: 1000px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #666;
    font-size: 20px;
}

/* #researchOppTable {
    width: 90%;
} */

.instructions {
   font-size:16px;
}

input.eligibleType, input.isApproved {
    display: inline;
    padding: 0;
    max-width: 25px;
    font-weight: normal;
}

#trainees {
    padding-top: 4px;
    padding-bottom: 2px;
}

#portal_ProjectDetails {
    position:absolute;
	height: 75vh;
	width: 90%;
    background-color: rgb(230, 230, 230);
    border: 1px solid #555;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 5px;
    max-height: 80%;
    top: 5%; /* push down by 50% of the height of the container */
    margin-top: 5%; /* bring it back up by half of it's height */
    overflow:auto;
}

#Project_Properties {
	justify-content: center;
	align-items: center;
}

#Details_Heading {
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    width: calc(100% - 60px);
    padding-bottom: 10px;
    float: left;
}

#Details_Close {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    /* padding-right: 10px; */
    /* float: right; */
}


  .grid-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 1rem;
    clear: both;
  }

  .grid-item {
    grid-column: 1;
    color: black;
    font-size: 16px;
  }

  .grid-value {
      font-size: 14px;
  }

#portal-root {

    z-index: 100;
    min-height: 100%;
}


.current {
    background-color: rgb(25, 126, 25);
}

.loginSection {
    float: right;
    font-size: 14px;
}

.loginSection a {
    color: white;
}

.projectWrapper {
    display: flex;

}

.projectData {
    padding: 10px;
    min-width: 200px;
    flex-basis: auto; /* default value */
    flex-grow: 1;
}

.projectHeading {
    padding: 10px;
    font-family: "Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif";
    font-size: 18px;
}

.projectTopHeader {
    padding: 10px;
    font-family: "Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif";
    font-size: 18px;
    background-color: Gainsboro;
}

.projectBottomHeader {
    padding: 10px;
    margin-top: 15px;
    font-family: "Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif";
    font-size: 18px;
}

.projectExpiryDate {
    font-weight: bold;
}

.projectTitle {
    font-weight: bold;
}


